import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Truncate from 'react-truncate';
import Routes from 'Routes';
import UserAvatar from 'components/UserAvatar';
import addContact from 'network/addContact';
import ErrorHollowIcon from 'assets/errorHollowIcon.svg';
import PDFIcon from 'assets/pdfIcon.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AudioCallIcon from './AudioCallIcon';
import VideoCallIcon from './VideoCallIcon';
import ChatContext from './ChatContext';

import { UserSessionContext } from '../UserSession';
import getFileSizeText from './utils/getFileSizeText';

const StyledDate = styled.div`
  text-align: center;
`;

const StyledDateText = styled.span`
  color: #FFF;
  border-radius: 2px;
  font-size: 12px;
  padding: 4px;
  background-color: #C9C9C9;
`;

const MessageContainer = styled.div`
  text-decoration: none;
  color: #3498DB;
  clear: both;
  display: flex;
  margin: 24px 0;
  text-align: ${({ isSelfSent }) => (isSelfSent ? 'right' : 'left')};
  flex-direction: ${({ isSelfSent }) => (isSelfSent ? 'row-reverse' : 'row')};
`;

const UserInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #484848;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
`;

const UserTag = styled.span`
  border-radius: 2px;
  background-color: #FFB7B5;
  color: #FFF;
  font-size: 12px;
  padding: 0 5px;
  margin-left: 9px;
`;

const UserDescription = styled.div`
  color: #484848;
  font-size: 12px;
  margin-top: 8px;
`;

const MessageContentContainer = styled.div`
  margin: 0 10px;
  display: block;
  max-width: 50%;
  word-break: break-word;
`;

const MessageContent = styled.div`
  display: flex;
  justify-content: ${({ isSelfSent }) => (isSelfSent ? 'flex-end' : 'flex-start')};
`;

const Message = styled.div`
  display: inline-flex;
  position: relative;
  padding: ${({ isAutoSent }) => (isAutoSent ? 0 : '16px')};
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  margin: 10px 0;
  width: fit-content;
  text-align: left;
  background-color:
    ${({ isSelfSent, isAutoSent }) => (isAutoSent && 'unset')
      || (isSelfSent && '#5C8DF3')
      || (!isSelfSent && '#FFF')};
  color:
    ${({ isSelfSent, isAutoSent }) => (isAutoSent && '#999')
    || (isSelfSent && '#FFF')
    || (!isSelfSent && '#484848')};
  white-space: pre-wrap;
`;

const StyledFilePreviewInChat = styled.a`
  text-decoration: none;
  background-color: #FFF;
  height: 84px;
  border-radius: 4px;
  border: 1px solid #DBDEE2;
  padding: 15px;
  display: flex;
  align-items: center;
  min-width: 210px;
  margin: 10px 0;
  direction: initial;

  .file-info {
    width: 80%;
    margin-left: 10px;
    text-align: left;

    .file-name {
      color: #17181E;
      font-size: 14px;
    }

    .file-size {
      color: #999;
      font-size: 12px;
    }
  }
`;

const StyledContactCard = styled.div`
  background-color: #FFF;
  height: 147px;
  min-width: 210px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  color: #484848;
  padding: 10px 12px;

  .left {
    width: 20%;
  }
  .right {
    width: 70%;
  }
`;

const StyledAddContactBtn = styled.div`
  width: 100%;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  color: #FFF;
  background-color: #5C8DF3;
  border-radius: 2px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
`;

const FileTypeIcon = styled.img`
  margin: 0;
`;

const FileInfo = styled.div`
  width: 80%;
  margin-left: 10px;
  text-align: left;
`;

const FileName = styled.div`
  color: #17181E;
  font-size: 14px;
`;

const FileSize = styled.div`
  color: #999;
  font-size: 12px;
`;

const SentTimeText = styled.div`
  color: #A2AAB3;
  font-size: 13px;
`;

const VideoCallMessageContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const StyledName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #484848;
`;

const StyledWebinarAlert = styled.div`
  .alert-header {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .alert-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom:10px;
  }
  .alert-start-soon {
    display: flex;
    margin-bottom: 10px;
  }
  .alert-session-period {
    margin-bottom: 15px;
  }
  .alert-btn {
    margin: 20px 0 10px 0;
    width: 100%;
    text-align: center;
    a.alert-join-live {
      display: block;
      text-decoration: none;
      background-color: #5C8DF3;
      color: #FFF;
      padding: 5px 20px;
      border-radius: 2px;
    }
  }
`;

const dateTagText = (date, t) => {
  const today = moment();
  if (today.isSame(date, 'day')) {
    return t('chat.today');
  }
  return date.format('ddd, MMM D');
};

const getSentTimeText = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString();
  const minutes = date.getMinutes().toString();
  const formatH = hours.length === 1 ? `0${hours}` : hours;
  const formatM = minutes.length === 1 ? `0${minutes}` : minutes;
  return `${formatH}:${formatM}`;
};

const ChatMessageContainer = ({
  msg,
  dateFlag,
  handleAvatarOnclick,
  resendMessage,
}) => {
  const { t } = useTranslation();
  const { userSession } = useContext(UserSessionContext);
  const msgDate = moment(msg.sentTime);
  const isRenderDate = msgDate.format('DD/MM/YYYY') !== dateFlag;
  const isSelfSent = msg.senderUserId === userSession.rongcloudUserId;
  const { needsRtcCall, call } = useContext(ChatContext);
  const { targetId, roomName } = useSelector((state) => state.chatTarget);

  const handleVideoCall = () => {
    if (needsRtcCall && msg.conversationType === window.RongIMLib.ConversationType.PRIVATE) {
      call(roomName, targetId, [targetId], window.RongIMLib.ConversationType.PRIVATE);
    }
  };

  const handleAddToContact = async () => {
    const contactId = msg?.content?.user?.rongcloudUserId;
    const userId = userSession?.rongcloudUserId;
    const success = await addContact(userId, contactId);
    console.log(success);
  };

  const renderMessageContent = () => {
    if (msg.objectName === 'S:Webinar:Alert') {
      return (
        <StyledWebinarAlert>
          <div className="alert-header"><b>Webinar notification</b></div>
          <div className="alert-title">{msg.content.message.content.title}</div>
          <div className="alert-start-soon">
            <div>Live starting soon </div>
            <VideoCallIcon color={isSelfSent ? '#FFF' : '#484848'} isSelfSent />
          </div>
          <div className="alert-session-period">
            {`Session period: ${msg.content.message.content.start_time} - ${msg.content.message.content.end_time}`}
          </div>
          <div className="alert-btn">
            <a className="alert-join-live" href={Routes.webinar}>
              Join Live
            </a>
          </div>
        </StyledWebinarAlert>
      );
    }
    switch (msg.messageType) {
      case window.RongIMClient.MessageType.TextMessage:
        if (typeof (msg.content.content) === 'string') {
          return window.RongIMLib.RongIMEmoji.symbolToEmoji(msg.content.content);
        }
        break;
      case window.RongIMClient.MessageType.AudioCallMessage:
        return (
          <>
            <AudioCallIcon color={isSelfSent ? '#FFF' : '#484848'} isSelfSent={isSelfSent} />
            <div>
              {msg.content.content}
            </div>
          </>
        );
      case window.RongIMClient.MessageType.VideoCallMessage:
        if (isSelfSent) {
          return (
            <VideoCallMessageContainer onClick={handleVideoCall}>
              <div>
                {msg.content.content}
              </div>
              <VideoCallIcon color={isSelfSent ? '#FFF' : '#484848'} isSelfSent />
            </VideoCallMessageContainer>
          );
        }
        return (
          <VideoCallMessageContainer onClick={handleVideoCall}>
            <VideoCallIcon color={isSelfSent ? '#FFF' : '#484848'} />
            <div>
              {msg.content.content}
            </div>
          </VideoCallMessageContainer>
        );
      case window.RongIMClient.MessageType.FileMessage:
        return (
          <StyledFilePreviewInChat
            href={msg.content.fileUrl}
            target="_blank"
            download
            rel="noopener noreferrer"
          >
            <FileTypeIcon src={PDFIcon} alt="pdf" />
            <FileInfo>
              <FileName>
                <Truncate lines={2} ellipsis="...">
                  {msg.content.name}
                </Truncate>
              </FileName>
              <FileSize>
                {getFileSizeText(msg.content.size)}
              </FileSize>
            </FileInfo>
          </StyledFilePreviewInChat>
        );
      case window.RongIMClient.MessageType.ContactCardMessage:
        return (
          <StyledContactCard>
            <div className="left">
              <UserAvatar
                name={msg?.content?.user?.name || 'user'}
              />
            </div>
            <div className="right">
              <StyledName>
                {msg?.content?.user?.name}
              </StyledName>
              <div>
                {msg?.content?.user?.email}
              </div>
              <div>
                {msg?.content?.user?.description}
              </div>
            </div>
            {
              !isSelfSent && (
                <StyledAddContactBtn onClick={handleAddToContact}>
                  Add to My Contacts
                </StyledAddContactBtn>
              )
            }
          </StyledContactCard>
        );
      default:
        break;
    }
    return null;
  };

  return (
    <React.Fragment key={msg.id}>
      {
        isRenderDate && (
          <StyledDate>
            <StyledDateText>
              {dateTagText(msgDate, t)}
            </StyledDateText>
          </StyledDate>
        )
      }
      <MessageContainer isSelfSent={isSelfSent}>
        <UserAvatar
          name={msg?.content?.user?.name || 'anonymous'}
          handleOnClick={
            () => handleAvatarOnclick(
              'PublicChatroom_ChatHistoryAvatar_Click',
              isSelfSent,
              msg.senderUserId,
              msg?.content?.user?.name,
            )
          }
        />
        <MessageContentContainer>
          <UserInfo>
            {msg?.content?.user?.name}
            {
              msg?.content?.user?.tag && (
                <UserTag>{msg?.content?.user?.tag}</UserTag>
              )
            }
          </UserInfo>
          <UserDescription>
            <Truncate lines={2} ellipsis="...">
              {msg?.content?.user?.description}
            </Truncate>
          </UserDescription>
          <MessageContent isSelfSent={isSelfSent}>
            {
              msg.sentFailed && (
                <img
                  role="presentation"
                  src={ErrorHollowIcon}
                  alt="Send failed"
                  onClick={() => resendMessage(msg)}
                />
              )
            }
            <Message
              isAutoSent={msg?.content?.user?.autoSent || false}
              isSelfSent={isSelfSent}
            >
              {renderMessageContent()}
            </Message>
          </MessageContent>
          <SentTimeText>
            {getSentTimeText(msg.sentTime)}
          </SentTimeText>
        </MessageContentContainer>
      </MessageContainer>
    </React.Fragment>
  );
};

ChatMessageContainer.propTypes = {
  msg: PropTypes.shape({
    id: PropTypes.string.isRequired,
    senderUserId: PropTypes.string.isRequired,
    sentTime: PropTypes.number.isRequired,
    sentFailed: PropTypes.bool,
    messageType: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    content: PropTypes.shape(),
    conversationType: PropTypes.number.isRequired,
  }).isRequired,
  dateFlag: PropTypes.string,
  handleAvatarOnclick: PropTypes.func.isRequired,
  resendMessage: PropTypes.func.isRequired,
};

ChatMessageContainer.defaultProps = {
  dateFlag: '',
};

export default ChatMessageContainer;
