import React, { Suspense, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Routes from 'Routes';
import {
  PAGE_NAMES, APP, EVENT_BANNERS, ENABLED_FEATURES,
  BRAND_COLOR,
} from 'appenv';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';
import defaultWebinarImg from 'assets/main_lobby/webinar.png';
import defaultExhibitionImg from 'assets/main_lobby/exhibition.png';
import exhibition623Background from 'assets/main_lobby/hall-paloalto623.png';
import webinar623Background from 'assets/main_lobby/webinar-paloalto623.png';
import exhibition624Background from 'assets/main_lobby/hall-ms624-01.jpg';
import webinar624Background from 'assets/main_lobby/webinar-ms624-01.jpg';
import exhibition711Background from 'assets/main_lobby/hall-scmp711.png';
import webinar711Background from 'assets/main_lobby/webinar-scmp711.png';
import { selectBannersByPosition, BannerPosition } from 'models/banners';
import { useSelector } from 'react-redux';
import LobbyFooterSwitch from './LobbyFooterSwitch';
import MobileBannerView from './MobileBannerView';

const exhibitionBackground = (() => {
  switch (APP) {
    case 'paloalto623':
      return `url(${exhibition623Background})`;
    case 'ms624':
      return `url(${exhibition624Background})`;
    case 'scmp711':
      return `url(${exhibition711Background})`;
    case 'lighthouse710':
    case Apps.Jetro817:
    case Apps.Mig0828:
    case Apps.Uzabase826:
    case Apps.Eventage824:
    case Apps.Emsd831:
      return BRAND_COLOR;
    default:
      return `url(${defaultExhibitionImg})`;
  }
})();

const webinarBackground = (() => {
  switch (APP) {
    case 'paloalto623':
      return `url(${webinar623Background})`;
    case 'ms624':
      return `url(${webinar624Background})`;
    case 'scmp711':
      return `url(${webinar711Background})`;
    case 'lighthouse710':
    case Apps.Jetro817:
    case Apps.Mig0828:
    case Apps.Uzabase826:
    case Apps.Eventage824:
    case Apps.Emsd831:
      return BRAND_COLOR;
    default:
      return `url(${defaultWebinarImg})`;
  }
})();

const RootContainer = styled.div`
  width: 100%;
  height: inherit;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const StyledLinkContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 24px 16px;
  padding-bottom: 49.56%;
  background: ${({ background }) => background};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
`;

const StyledText = styled.div`
  flex: 0 0 auto;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.408px;
  color: #FFF;
`;

const FooterWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
`;

const hideWebinarTextList = ['paloalto623', 'ms624', 'scmp711'];

const MobileLobbyView = () => {
  const { t } = useTranslation();
  const [showHallText, showWebinarText] = useMemo(() => (hideWebinarTextList.includes(APP) ? [false, false] : [true, true]), []);
  const [showHallLink, showWebinarLink] = useMemo(() => envSwitch([
    [Apps.Jetro817, [true, false]],
  ], [true, true]), []);

  const HallLink = (() => {
    switch (APP) {
      default: return (
        <StyledLink to={Routes.hall}>
          {showHallText && (
            <StyledText>
              {PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall')}
            </StyledText>
          )}
        </StyledLink>
      );
    }
  })();

  const WebinarLink = (() => {
    switch (APP) {
      case 'ms624': return (
        <>
          {showWebinarText && (
            <StyledText>
              {PAGE_NAMES.webinar}
            </StyledText>
          )}
        </>
      );
      default: return (
        <StyledLink to={Routes.webinar}>
          {showWebinarText && (
            <StyledText>
              {PAGE_NAMES.webinar || t('page.webinar', 'Webinars')}
            </StyledText>
          )}
        </StyledLink>
      );
    }
  })();

  const mobileBanners = useSelector(selectBannersByPosition(BannerPosition.mobile));

  return (
    <RootContainer>
      <MobileBannerView
        assets={ENABLED_FEATURES.cmsBanner && mobileBanners?.length > 0 ? mobileBanners : EVENT_BANNERS.mobile}
      />
      {showHallLink && (
        <StyledLinkContainer background={exhibitionBackground}>
          {HallLink}
        </StyledLinkContainer>
      )}
      {showWebinarLink && (
        <StyledLinkContainer background={webinarBackground}>
          {WebinarLink}
        </StyledLinkContainer>
      )}
      <FooterWrapper>
        <Suspense fallback={null}>
          <LobbyFooterSwitch />
        </Suspense>
      </FooterWrapper>
    </RootContainer>
  );
};

export default MobileLobbyView;
