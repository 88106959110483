import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Booth711Borders from 'assets/booths/711-upgraded/booth-border.svg';
import UpgradedBoothLines from 'assets/booths/711-upgraded/booth-lines.svg';
import UpgradedBackdropSvg from 'assets/booths/711-upgraded/BoothBackdrop';
import UpgradedBooth711Contents from 'assets/booths/711-upgraded/booth-foreground.svg';

const UpgradedBackdrop = styled(UpgradedBackdropSvg)<{ color: string }>`
  display: block;
  position: absolute;
  top: -31px;
  width: 100%;
  height: 100%;
`;

const UpgradedBoothLinesImage = styled.img`
  display: block;
  position: absolute;
  width: 84%;
  height: 100%;
  left: 85px;
  top: -80px;
  margin: 0 auto;
`;

const UpgradedScreenContainer = styled.div<{ boothColor: string }>`
  ${UpgradedBackdrop} path {
    fill: ${({ boothColor }) => boothColor};
  }
`;

const UpgradedBoothContents = styled.img`
  width: 88%;
  position: absolute;
  left: 69px;
  top: -30px;
`;

const UpgradedBorders = styled.img`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
`;

const ScreenBackground = ({ boothColor }) => (
  <UpgradedScreenContainer boothColor={boothColor}>
    <UpgradedBackdrop />
    <UpgradedBoothLinesImage src={UpgradedBoothLines} />
    <UpgradedBorders src={Booth711Borders} />
    <UpgradedBoothContents src={UpgradedBooth711Contents} />
  </UpgradedScreenContainer>
);

ScreenBackground.propTypes = {
  boothColor: PropTypes.string,
};

ScreenBackground.defaultProps = {
  boothColor: undefined,
};

export default ScreenBackground;
