import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import { Booth, DesignElement } from 'models/cms/booths';
import ScreenBackground from './BoothBackground';
import TanShelf from './BrochureShelf';
import BoothContainer from '../../../components/BoothContainer';
import GenericCMSBoothBanner from '../../../components/GenericCMSBoothBanner';
import BoothSaleContainer from '../BoothSaleContainer';

const BoothWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.3);
  top: 102px;
`;

const TopBannerContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  width: 276px;
  height: 76px;
  top: 6px;
  left: calc((100% - 248px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const TopBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowDeskContainer = styled.div`
  position: absolute;
  top: 125px;
  width: 222px;
  height: 106px;
  left: calc((100% - 192px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const LeftBannerContainer = styled.div`
  height: 264px;
  width: 122.5px;
  position: absolute;
  top: 104px;
  left: 94px;
`;

const RightBannerContainer = styled.div`
  height: 264px;
  width: 122.5px;
  position: absolute;
  top: 105px;
  right: 63px;
`;

const BrochureContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  bottom: 111px;
  right: -38px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
  user-select: text;
`;

const LeftSaleContainer = styled(SaleContainer)`
  right: auto;
  left: 0;
`;

const CenterBannerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  position: absolute;
  top: 293px;
  width: 312px;
  height: 83px;
  left: calc((100% - 283px) / 2);
`;

export interface BannerProps {
  booth: Booth;
  handleClick: (type: string) => void;
  isPreview?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  booth,
  handleClick = () => null,
  isPreview = false,
}: BannerProps) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const leftColor: string = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'left_color')?.color, [booth.designElements]);
  const rightColor: string = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'right_color')?.color, [booth.designElements]);
  const leftBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'left_banner'), [booth.designElements]);
  const rightBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'right_banner'), [booth.designElements]);
  const topBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'top_banner'), [booth.designElements]);
  const centerBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode.name === 'middle_banner'), [booth.designElements]);
  const bottomBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode.name === 'bottom_banner'), [booth.designElements]);
  const showRightRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'right_representative'), [isPreview, booth.designElements]);
  const showLeftRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'left_representative'), [isPreview, booth.designElements]);
  return (
    <BoothContainer needOffset={!isPreview}>
      <BoothWrapper>
        <BackgroundContainer>
          <ScreenBackground
            boothColor={leftColor || rightColor || '#CF2F2F'}
            // boothColorRight={rightColor || leftColor || '#CF2F2F'}
          />
        </BackgroundContainer>
        {
          topBanner && (
            <TopBannerContainer enableClick={!!booth.website} onClick={() => booth.website && window.open(`http://${booth.website.replace(/\b(https:\/\/|http:\/\/)/, '')}`, '_blank')}>
              <TopBannerWrapper>
                <GenericCMSBoothBanner
                  bannerData={topBanner}
                />
              </TopBannerWrapper>
            </TopBannerContainer>
          )
        }
        {
          centerBanner && (
            <ShowDeskContainer>
              <CenterBannerContainer>
                <GenericCMSBoothBanner
                  onBannerClick={handleClick as any}
                  autoPlayVideo={!isPreview}
                  bannerData={centerBanner}
                />
              </CenterBannerContainer>
            </ShowDeskContainer>
          )
        }
        {
          bottomBanner && (
            <BottomContainer>
              <CenterBannerContainer>
                <GenericCMSBoothBanner
                  onBannerClick={handleClick as any}
                  autoPlayVideo={!isPreview}
                  bannerData={bottomBanner}
                />
              </CenterBannerContainer>
            </BottomContainer>
          )
        }
        {
          leftBanner && (
            <LeftBannerContainer>
              <GenericCMSBoothBanner
                bannerData={leftBanner}
              />
            </LeftBannerContainer>
          )
        }
        {
          rightBanner && (
            <RightBannerContainer>
              <GenericCMSBoothBanner
                bannerData={rightBanner}
              />
            </RightBannerContainer>
          )
        }
        <BrochureContainer enableClick={booth.hasAttachment} onClick={() => handleClick('attachment')}>
          <TanShelf hasAttachment={booth.hasAttachment} />
        </BrochureContainer>
        {
          showRightRepresentative && (
            <SaleContainer id="reps">
              <BoothSaleContainer booth={booth} sale="male-right" />
            </SaleContainer>
          )
        }
        {
          showBannerModal && (
            <Lightbox
              mainSrc={null}
              onCloseRequest={() => setShowBannerModal(false)}
            />
          )
        }
        {
          showLeftRepresentative && (
            <LeftSaleContainer id="reps">
              <BoothSaleContainer booth={booth} sale="male-left" />
            </LeftSaleContainer>
          )
        }
      </BoothWrapper>
    </BoothContainer>
  );
};

export default Banner;
